<template>
  <form
    class="form"
    :class="{ mobile: props.isMobile }"
    data-t="change-password-form"
    @submit.prevent="handleSubmit"
  >
    <MFormHeader
      v-if="props.isMobile"
      :with-back-button="false"
      class="form-header"
      data-t="tabs"
      @back="handleFormClose"
    >
      <h2 class="title" data-t="change-password-form-title-glz0">
        {{ t('changePassword.title') }}
      </h2>
    </MFormHeader>
    <div class="form-wrapper">
      <div v-if="!props.isMobile" class="form-header">
        <h2 class="title" data-t="change-password-form-title-xb7m">
          {{ t('changePassword.title') }}
        </h2>
      </div>
      <StInputPassword
        v-bind="oldPassword.componentBindings"
        :label="t('changePassword.currentLabel')"
        :placeholder="t('changePassword.currentPlaceholder')"
        type="password"
        :error="!isNewPasswordError ? !!errorMessage : false"
        :error-message="!isNewPasswordError ? errorMessage : ''"
        :size="props.isMobile ? 'm' : 'l'"
        data-t="old-password"
      />
      <StInputPassword
        v-bind="newPassword.componentBindings"
        :label="t('changePassword.newLabel')"
        :placeholder="t('changePassword.newPlaceholder')"
        :rules="passwordValidators"
        type="password"
        :error="isNewPasswordError || !!newPassword.error"
        :error-message="isNewPasswordError ? errorMessage : ''"
        :size="props.isMobile ? 'm' : 'l'"
        rules-position="top"
        data-t="new-password"
      />
      <StButton
        submit
        :label="t('changePassword.submit')"
        :size="props.isMobile ? 'l' : 'xl'"
        data-t="submit-change-button-rrxp"
        :loading="requestStatus === 'pending'"
        class="submit-button"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
import { required, useForm } from '@st/validate'
import { useUserStore } from '../../stores/useUserStore'
import { usePasswordValidator } from '../../composables/usePasswordValidator'
import MFormHeader from '../parts/MFormHeader.vue'

const { t } = useI18n()

const props = defineProps<{
  isMobile?: boolean
}>()

const {
  fields: { oldPassword },
} = useForm({
  fieldsSchema: {
    oldPassword: {
      initialValue: '',
      validators: [
        {
          rule: required,
          triggerErrorDisplay: 'never',
        },
      ],
    },
  },
})

const { passwordValidators, password: newPassword } = usePasswordValidator()

const {
  execute: changePassword,
  error: changePasswordError,
  status: requestStatus,
} = useStFetch('/password/change', {
  method: 'post',
  body: computed(() => ({
    oldPassword: oldPassword.value,
    newPassword: newPassword.value,
  })),
  immediate: false,
  watch: false,
})

const { tryToAuthenticate } = useUserStore()
const toast = useToast()

const isNewPasswordError = ref(false)
const errorMessage = computed(() => {
  const errorBody = changePasswordError.value?.data
  if (!errorBody) return undefined

  const { error: errorCode } = errorBody

  switch (errorCode) {
    case 'VALIDATION_ERROR': {
      isNewPasswordError.value = true
      return t('changePassword.errors.validation')
    }
    case 'USER_INVALID_PASSWORD': {
      isNewPasswordError.value = false
      return t('changePassword.errors.invalidPassword')
    }
    case 'USER_PASSWORD_REQUIREMENT_VIOLATION': {
      isNewPasswordError.value = true
      return t('changePassword.errors.passwordRequarement')
    }
    case 'USER_PASSWORD_DUPLICATION': {
      isNewPasswordError.value = true
      return t('changePassword.errors.duplicationPassword')
    }

    default: {
      isNewPasswordError.value = false
      return ''
    }
  }
})

const router = useRouter()
function handleFormClose() {
  router.replace({ query: {} })
}

async function handleSubmit() {
  await changePassword()

  if (!changePasswordError.value) {
    await tryToAuthenticate()

    toast.open({
      label: t('changePassword.success'),
      type: 'positive',
    })

    handleFormClose()
  }
}
</script>

<style scoped>
.title {
  margin: 0;
  font: var(--desktop-text-xl-semibold);
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-200);
}

.submit-button {
  margin-top: var(--spacing-100);
}

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;

  width: 480px;
  padding: var(--spacing-500) var(--spacing-600) var(--spacing-500)
    var(--spacing-600);

  &.mobile {
    width: 100%;
    padding: 0;
    padding-top: var(--spacing-250);
    padding-bottom: var(--spacing-200);

    .title {
      font: var(--mobile-title-2-semibold);
    }

    .form-wrapper {
      gap: var(--spacing-200);
      padding: 0 var(--spacing-200);
    }

    .form-header {
      padding-right: var(--spacing-100);
      padding-left: var(--spacing-200);
    }

    .submit-button {
      margin-top: auto;
    }
  }
}
</style>
